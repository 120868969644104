@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.active {
  color: #1d4ed8; 
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.min-h-screen {
  min-height: 100vh;
}

.absolute {
  position: absolute;
}
.nav-link:hover {
  color: #f39c12; 
}

.macbook-frame {
  position: relative;
  width: 100%;
  padding-top: 62.5%; 
  background-image: url('../src/assets/macbook-frame.png');
  background-size: cover;
  background-position: center;
}

.video-content {
  position: absolute;
  top: 11%; 
  left: 12.5%; 
  width: 75%; 
  height: 78%; 
}
